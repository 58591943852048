import React, { useState, useCallback, useEffect } from 'react'
import { Nav, Navbar as BootstrapNavBar, Image } from 'react-bootstrap'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedin,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import classNames from 'classnames'
import { Location } from '@reach/router'

import { BottomLinks } from './BottomLinks'
import { Container } from '../Container'
import { Search } from '../Search'
import { SearchIcon } from './SearchIcon'

import LogoChampionsLeague from '../../images/BCL_Logo_2Col_RGB_Large_WhiteBG.png'
import LogoPR from '../../images/logo-pr.png'
import LogoWeCare from '../../images/logo-we-care.png'
import LogoLBA from '../../images/lega-basket-logo.png'
import LogoUNA from '../../images/logo-una.png'

import * as classes from './navbar.module.scss'

const socials = [
  {
    icon: faTiktok,
    name: 'TikTok',
    href: 'https://www.tiktok.com/@pallacanestroreggiana',
  },
  {
    icon: faTwitter,
    name: 'Twitter',
    href: 'https://twitter.com/pallacreggiana',
  },
  {
    icon: faFacebookF,
    name: 'Facebook',
    href: 'https://www.facebook.com/pallacanestroreggiana/',
  },
  {
    icon: faInstagram,
    name: 'Instagram',
    href: 'https://www.instagram.com/pallacanestroreggiana/',
  },
  {
    icon: faYoutube,
    name: 'YouTube',
    href: 'https://www.youtube.com/channel/UCSWK9ximwWQ56itm56CBT7A',
  },
  {
    icon: faLinkedin,
    name: 'Linkedin',
    href: 'https://it.linkedin.com/company/pallacanestroreggiana',
  },
]

const logos = [
  {
    image: LogoUNA,
    alt: 'Logo Una Hotels',
    href: 'https://gruppouna.it',
  },
  {
    image: LogoLBA,
    alt: 'Logo LegaBasket',
    href: 'https://www.legabasket.it/',
  },
  {
    image: LogoChampionsLeague,
    alt: 'Logo Basketball Champions League',
    href: 'https://www.championsleague.basketball/en',
  },
]

export const NavBar = React.memo(() => {
  const [showSearch, setShowSearch] = useState(false)
  const { becomeSponsor } = useStaticQuery(graphql`
    query NavBar {
      becomeSponsor: wordpressWpMedia(title: { eq: "Diventa sponsor 2023" }) {
        localFile {
          publicURL
        }
      }
    }
  `)

  const openSearch = useCallback(() => {
    document.body.classList.add('fixed')
    setShowSearch(true)
  }, [showSearch])

  const closeSearch = useCallback(() => {
    document.body.classList.remove('fixed')
    setShowSearch(false)
  }, [showSearch])

  useEffect(() => {
    const stickyNavBars = document.querySelectorAll<HTMLElement>(
      `.${classes.sticky}`
    )

    if (typeof window !== 'undefined') {
      const Sticky = require('stickyfilljs')

      Sticky.add(stickyNavBars)

      return () => {
        Sticky.removeAll()
      }
    }
  })

  return (
    <>
      <BootstrapNavBar className={classes.top} expand={'xl'}>
        <Container className={classes.container}>
          <BootstrapNavBar.Toggle className={classes.toggle}>
            <span />
            <span />
          </BootstrapNavBar.Toggle>

          <Nav className={classNames('ml-auto', classes.socials, classes.nav)}>
            {socials.map(({ icon, name, href }) => (
              <Nav.Link
                className={classes.navLink}
                href={href}
                aria-label={name}
                key={name}
                target={'_blank'}
              >
                <FontAwesomeIcon icon={icon} />
              </Nav.Link>
            ))}
          </Nav>

          <SearchIcon className={classes.topSearchIcon} click={openSearch} />

          <BootstrapNavBar.Collapse className={classes.collapse}>
            <Nav className={classNames(classes.mobileBottomLinks)}>
              <BottomLinks />
            </Nav>

            <Nav className={classes.nav}>
              <Nav.Link as={Link} to={'/contatti'} className={classes.navLink}>
                Contatti
              </Nav.Link>

              <Nav.Link as={Link} to={'/società'} className={classes.navLink}>
                Società
              </Nav.Link>

              <Nav.Link
                href={'/diventa-sponsor'}
                className={classes.navLink}
                target={'_blank'}
                rel={'noreferrer noopener'}
              >
                Diventa Sponsor
              </Nav.Link>

              <Nav.Link
                as={Link}
                to={'/newsletter'}
                className={classes.navLink}
              >
                Newsletter
              </Nav.Link>
            </Nav>
          </BootstrapNavBar.Collapse>
        </Container>
      </BootstrapNavBar>

      <BootstrapNavBar className={classes.middle} expand={'lg'}>
        <Container className={classes.container}>
          <BootstrapNavBar.Brand className={classes.brand}>
            <Link to={'/'}>
              <Image src={LogoPR} alt={'Logo Pallacanestro Reggiana'} />
            </Link>

            <Link to={'/wecare'}>
              <Image src={LogoWeCare} alt={'Logo Pallacanestro Reggiana'} />
            </Link>
          </BootstrapNavBar.Brand>

          <Nav className={classes.nav}>
            {logos.map(({ image, alt, href }) => (
              <Nav.Link
                className={classes.logo}
                href={href}
                target={'_blank'}
                rel={'noreferrer noopener'}
                key={alt}
              >
                <Image src={image} alt={alt} fluid />
              </Nav.Link>
            ))}
          </Nav>
        </Container>
      </BootstrapNavBar>

      <BootstrapNavBar className={classes.bottom} expand={'lg'}>
        <Container className={classes.container}>
          <Nav className={classes.nav}>
            <BottomLinks />
          </Nav>

          <Nav className={'ml-auto'}>
            <SearchIcon
              className={classes.bottomSearchIcon}
              click={openSearch}
            />
          </Nav>
        </Container>
      </BootstrapNavBar>

      <Location>
        {({ location }) => (
          <Search show={showSearch} close={closeSearch} location={location} />
        )}
      </Location>
    </>
  )
})
